@keyframes cursoranim {
    0% {opacity:1;}
    50% {opacity:1;}
    51% {opacity:0;}
    100% {opacity:0;}
  }

#cursor{
    width:.5rem;
    height:1rem;
    background:lime;
    display:inline-flex;
    animation-name: cursoranim;
    animation-duration: 1s;
    animation-iteration-count: infinite;

}

