@font-face {
  font-family: "Oxanium", cursive;
  font-family: "Roboto", sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=Oxanium:wght@300;700&family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");

@import-normalize;

html {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background: url("/img/moon.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  /* padding: 10vw 3rem 0 3rem; */
}

#root {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
